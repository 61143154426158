<template>
  <div>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 340px)">
      <el-table-column align="center" label="序号" width="50" type="index"></el-table-column>
      <el-table-column prop="productName" align="center" label="充值名称"></el-table-column>
      <el-table-column prop="rechargeAccount" align="center" label="充值号码"></el-table-column>
      <el-table-column prop="price" align="center" label="充值金额"></el-table-column>
      <el-table-column prop="payAmount" align="center" label="消费金额"></el-table-column>
      <el-table-column prop="payIntegral" align="center" label="消费积分"></el-table-column>
      <el-table-column prop="createTime" align="center" label="充值时间"></el-table-column>
    </el-table>
    <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { getWlynProductRechargePage } from '@/api/customer'
export default {
  name: "DetailCoupons",
  props: ['userPhone'],
  data() {
    return {
      search: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      list: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      let param = Object.assign({ userPhone: this.userPhone }, this.search)
      getWlynProductRechargePage(param).then(res => {
        res.data = res.data || {}
        this.total = res.data.total
        this.list = res.data.records
      })
    },
    handleCurrentChange(val) {
      this.search.pageNum = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.getList()
    },
  }

}
</script>

<style>
</style>