<template>
  <div>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 340px)">
      <el-table-column align="center" label="序号" width="50" type="index"></el-table-column>
      <el-table-column align="center" label="机构名称" width="150" prop="orgName"></el-table-column>
      <el-table-column align="center" label="发放类型">
        <template slot-scope="scope">
          {{exchangeType[scope.row.exchangeType]}}
        </template>
      </el-table-column>
      <el-table-column prop="customerName" align="center" label="姓名" width="130"></el-table-column>
      <!-- <el-table-column prop="number" align="center" label="编号" width="130"></el-table-column> -->
      <el-table-column prop="title" align="center" label="批次" width="130"></el-table-column>
      <el-table-column prop="exchangeAmount" align="center" label="积分" width="130"></el-table-column>
      <el-table-column prop="createTime" align="center" label="发放时间"></el-table-column>
      <el-table-column align="center" label="领取状态" width="110">
        <template slot-scope="scope">
          <el-tag class="el-tag-style" size="mini" :type="typeList[scope.row.isReceive] && typeList[scope.row.isReceive].type" disable-transitions>{{typeList[scope.row.isReceive] && typeList[scope.row.isReceive].text}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="receiveTime" align="center" label="领取/撤销时间">
        <template slot-scope="scope">
          {{scope.row.isReceive ==2 ? scope.row.updateTime : scope.row.receiveTime}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" v-if="!hidden">
        <template slot-scope="scope">
          <el-button icon="el-icon-refresh-left" size="mini" @click="recall(scope.row.infoId,scope.$index)" :disabled="!scope.row.isReceive == 0">撤回</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { removeBack } from '@/api/integrals'
import { receiveListByPhone } from '@/api/customer'
export default {
  name: "DetailIntegral",
  props: ['userPhone', 'hidden', 'userNumber'],
  data() {
    return {
      exchangeType: ['核销码', '前三后四', '直接发放', '工号发放'],
      search: {
        isPage: 1,
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      list: [],
      typeList: [
        { type: 'primary', text: '未领取' },
        { type: 'success', text: '已领取' },
        { type: 'warning', text: '已撤回' },
      ],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      let param = Object.assign({ phone: this.userPhone, number: this.userNumber }, this.search)
      receiveListByPhone(param).then(res => {
        res.data = res.data || {}
        this.total = res.data.total
        this.list = res.data.records
      })
    },
    handleCurrentChange(val) {
      this.search.pageNum = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.getList()
    },
    recall(id, index) {
      this.$confirm('是否撤回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        removeBack(id).then((response) => {
          if (response.code == 200) {
            this.getList()
          }
          this.$message({
            type: response.code == 200 ? 'success' : 'default',
            duration: 2000,
            message: response.msg || response.error_msg,
          });
        })
      });
    },
  }
}
</script>

<style>
</style>