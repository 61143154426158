<template>
  <div>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 340px)">
      <el-table-column align="center" label="序号" width="50" type="index"></el-table-column>
      <el-table-column prop="amount" align="center" label="积分" width="130"></el-table-column>
      <el-table-column prop="createTime" align="center" label="提交时间"></el-table-column>
      <el-table-column prop="isUse" align="center" label="状态" width="80">
        <template slot-scope="scope">
          <el-tag class="el-tag-style" size="mini" :type="typeList[scope.row.integralWithdrawalState] && typeList[scope.row.integralWithdrawalState].type" disable-transitions>{{typeList[scope.row.integralWithdrawalState] && typeList[scope.row.integralWithdrawalState].text}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="发放/退回时间">
        <template slot-scope="scope">
          {{scope.row.integralWithdrawalState == 2?scope.row.returnTime:scope.row.grantTime}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-refresh-right" size="mini" @click="editDetail(scope.row.id,4)" :disabled="scope.row.integralWithdrawalState != 0" v-if="!hidden">退回</el-button>
          <el-button icon="el-icon-view" size="mini" @click="editDetail(scope.row.id,1)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total">
    </el-pagination>
    <EditDetail v-if="isEditDialog" :isAddDialog.sync="isEditDialog" :editId="editId" :editType="editType" :isHide="hidden" @update:isAddDialog="dialogUpdate"></EditDetail>
  </div>
</template>

<script>
import { drawalListByPhone } from '@/api/customer'
import EditDetail from '@/views/issueList/components/EditDetail.vue'
export default {
  name: "DetailExchange",
  components: { EditDetail },
  props: ['userPhone', 'hidden'],
  data() {
    return {
      search: {
        isPage: 1,
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      list: [],
      typeList: [
        { type: 'primary', text: '未发放' },
        { type: 'success', text: '已发放' },
        { type: 'warning', text: '已退回' },
      ],
      editType: '',
      editId: '',
      isEditDialog: false,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    dialogUpdate() {
      if (this.editType == 4) {
        this.getList()
      }
    },
    getList() {
      let param = Object.assign({ userPhone: this.userPhone }, this.search)
      drawalListByPhone(param).then(res => {
        res.data = res.data || {}
        this.total = res.data.total
        this.list = res.data.records
      })
    },
    handleCurrentChange(val) {
      this.search.pageNum = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.getList()
    },
    editDetail(id, type) {
      this.editType = type;
      this.editId = id;
      this.isEditDialog = true;
    },
  }

}
</script>

<style>
</style>