<template>
  <div>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 340px)">
      <el-table-column align="center" label="序号" width="50" type="index"></el-table-column>
      <el-table-column prop="vouchersName" align="center" label="标题"></el-table-column>
      <el-table-column align="center" label="供应商">
        <template slot-scope="scope">
          {{scope.row.recharge==1?'鹤大师':'车多'}}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" align="center" label="兑换时间"></el-table-column>
      <el-table-column prop="integralPrice" align="center" label="消费积分" width="150"></el-table-column>
      <el-table-column prop="amountPrice" align="center" label="消费金额" width="150"></el-table-column>
      <el-table-column align="center" label="使用状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.vouchersType!=1 || (scope.row.vouchersType==1 && scope.row.couponCode)" class="el-tag-style" size="mini" :type="typeList[scope.row.isUsed] && typeList[scope.row.isUsed].type" disable-transitions>{{typeList[scope.row.isUsed] && typeList[scope.row.isUsed].text}}</el-tag>
          <el-tag v-else class="el-tag-style" size="mini" type="warning" disable-transitions>失效券</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="usedTime" align="center" label="使用时间"></el-table-column>
    </el-table>
    <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { voucherListByPhone } from '@/api/customer'
export default {
  name: "DetailCoupons",
  props: ['userPhone'],
  data() {
    return {
      search: {
        isPage: 1,
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      list: [],
      typeList: [
        { type: 'primary', text: '未使用' },
        { type: 'success', text: '已使用' },
        { type: 'warning', text: '已撤回' },
        { type: 'info', text: '已赠送' },
      ],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      let param = Object.assign({ phone: this.userPhone }, this.search)
      voucherListByPhone(param).then(res => {
        res.data = res.data || {}
        this.total = res.data.total
        this.list = res.data.records
      })
    },
    handleCurrentChange(val) {
      this.search.pageNum = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.getList()
    },
  }

}
</script>

<style>
</style>