<template>
  <div>
    <div style="display:flex;margin-bottom:20px;" v-if="!hidden">
      <el-input v-model="value" type="textarea" :rows="4" style="width:80%"></el-input>
      <el-button size='small' type="primary" style="height:30px;margin-left:40px" v-debounce="submit">保存</el-button>
    </div>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 450px)">
      <el-table-column align="center" label="序号" width="50" type="index"></el-table-column>
      <el-table-column prop="time" align="center" label="创建时间"></el-table-column>
      <el-table-column prop="remark" align="center" label="备注内容" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createTime" align="center" label="操作" v-if="!hidden">
        <template slot-scope="scope">
          <el-button icon="el-icon-delete" type="danger" size="mini" @click="deleteClick(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { addUserOperation, deleteUserOperation, getUserOperationByPhone } from '@/api/customer'
export default {
  name: "DetailCoupons",
  props: ['hidden', 'userPhone'],
  data() {
    return {
      list: [],
      value: ''
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getUserOperationByPhone({ phone: this.userPhone }).then(res => {
        this.list = res.data
      })
    },
    submit() {
      if (!this.value) {
        this.$message.warning('请输入备注')
        return
      }
      let data = {
        remark: this.value,
        phone: this.userPhone
      }
      addUserOperation(data).then(res => {
        if (res.code == 200) {
          this.value = ''
          this.$message.success('添加成功')
          console.log(this.$parent)
          this.$parent.onLoad()
          this.getList()
        } else {
          this.$message.error(res.msg || '添加失败')
        }
      })
    },
    deleteClick(item) {
      this.$confirm('确认删除该备注记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteUserOperation({ id: item.id }).then(res => {
          if (res.code == 200) {
            this.$message.success('删除成功')
            this.$parent.onLoad()
            this.getList()
          } else {
            this.$message.error(res.msg || '删除失败')
          }
        })
      }).catch(() => {
      })
    }
  }

}
</script>

<style>
</style>