<template>
  <div>
    <el-row style="width:100%">
      <el-col :span="22" style="height:150px;display:flex">
        <el-avatar shape="square" size="large" :src="userInfo.headImgUrl || 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'" style="height:100%;width:150px"></el-avatar>
        <div style="margin-left:30px">
          <div style="margin:10px 0">姓名：{{userName}}</div>
          <div style="margin:10px 0">手机号：{{userPhone}}</div>
          <div style="margin:10px 0">积分：{{userInfo.remainderIntegral}} / {{userInfo.totalIntegral}}</div>
          <div>券：{{userInfo.remainderVoucher}} / {{userInfo.totalVoucher}}</div>
        </div>
        <!-- <div class="btn-box" v-if="!hidden"> -->
        <div class="btn-box" v-if="false">
          <el-button @click="changeInfo('name')" type="primary" size="mini">修改姓名</el-button>
          <el-button @click="changeInfo('phone')" type="primary" size="mini">修改手机号</el-button>
          <!-- <el-button @click="changeInfo('number')" type="primary" size="mini">修改编号</el-button> -->
        </div>
      </el-col>
      <el-col :span="2">
        <el-button @click="goBack" type="primary">返回</el-button>
      </el-col>
    </el-row>

    <el-tabs v-model="activeName" v-if="changeShow">
      <el-tab-pane :label="`积分 (${userInfo.integralTotal||0})`" name="first">
        <DetailIntegral v-if="activeName=='first'" :userPhone='userPhone' :hidden="hidden" :userNumber="userNumber"></DetailIntegral>
      </el-tab-pane>
      <el-tab-pane :label="`提现 (${userInfo.withdrawalTotal||0})`" name="second" v-if="!userNumber">
        <DetailExchange v-if="activeName=='second'" :userPhone='userPhone' :hidden="hidden"></DetailExchange>
      </el-tab-pane>
      <el-tab-pane :label="`券 (${userInfo.vouchersTotal||0})`" name="third" v-if="!userNumber">
        <DetailCoupons v-if="activeName=='third'" :userPhone='userPhone'></DetailCoupons>
      </el-tab-pane>
      <el-tab-pane :label="`充值 (${userInfo.rechargeTotal||0})`" name="fours" v-if="!userNumber">
        <DetailOil v-if="activeName=='fours'" :userPhone='userPhone'></DetailOil>
      </el-tab-pane>
      <el-tab-pane :label="`备注 (${userInfo.userOperationTotal||0})`" name="five" v-if="!userNumber">
      </el-tab-pane>
    </el-tabs>
    <DetailRemark v-if="activeName=='five'" :userPhone='userPhone' :hidden="hidden"></DetailRemark>
    <ChangeInfo :show.sync="showChangeInfo" v-if="showChangeInfo" :type="changeType" :phone="userPhone" :userNumber="userNumber"></ChangeInfo>
  </div>
</template>

<script>
import { getWxUserInfo } from '@/api/customer'
import ChangeInfo from './components/ChangeInfo.vue'
import DetailCoupons from './components/DetailCoupons.vue'
import DetailExchange from './components/DetailExchange.vue'
import DetailIntegral from './components/DetailIntegral.vue'
import DetailOil from './components/DetailOil.vue'
import DetailRemark from './components/DetailRemark.vue'
export default {
  name: 'customerDetail',
  components: { DetailCoupons, DetailExchange, DetailIntegral, DetailOil, ChangeInfo, DetailRemark },
  data() {
    return {
      activeName: 'first',
      userPhone: '',
      userName: '',
      userInfo: {},
      search: '',
      hidden: false,
      showChangeInfo: false,
      changeType: '',
      userNumber: '',
      changeShow: true
    }
  },
  created() {
    this.userPhone = this.$route.query && this.$route.query.phone
    this.userNumber = (this.$route.query && this.$route.query.userType == 1) ? this.$route.query.number : ''
    console.log(this.userNumber);
    this.userName = this.$route.query && this.$route.query.name
    this.search = this.$route.query && this.$route.query.search
    this.hidden = this.$route.query && Number(this.$route.query.hidden)
    console.log(this.hidden, this.$route)
    this.onLoad()
  },
  watch: {
    showChangeInfo(val) {
      if (!val) this.changeShow = false
      this.$nextTick(() => {
        this.changeShow = true
      })
    }
  },
  methods: {
    onLoad() {
      let param = {
        phone: this.userPhone,
        number: this.userNumber
      }
      getWxUserInfo(param).then(res => {
        this.userInfo = res.data
      })
    },
    changeInfo(type) {
      this.showChangeInfo = true
      this.changeType = type
    },
    goBack() {
      sessionStorage.setItem('search', this.search)
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped>
.btn-box {
  margin-left: 200px;
  align-self: center;
}
</style>